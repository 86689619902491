import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import LazyLoad from 'react-lazy-load'

export const ImagePass = (props) => {

  const { src, position = "absolute", className = null, alt, lazyLoad = false, publicURL = false, returnBase64 = false, height, width, fcpLoaded, lazy = true } = props;

  if ( !src ) return null

  const srcPass = src.localFile && src.localFile.childImageSharp ? src.localFile.childImageSharp : null

  //Base64
  if (returnBase64 && srcPass) { //Header Images

    return <div
      className={`gatsby-image base64${className ? ` ${className}` : '' }`}
      style={{
         position: position,
         left: 0,
         top: 0,
         width: "100%",
         minHeight:"100%",
       }}
    ><img src={srcPass.base64.base64} alt={alt ? alt : src.title} /></div>
  }

  //Fixed
  if (srcPass && srcPass.fixed) {
    if (returnBase64 && srcPass.fixed.base64) { //Base64 from fixed
      return <div
        className={`gatsby-image${className ? ` ${className}` : '' } base64`}
        style={{
           position: position,
           left: 0,
           top: 0,
           width: "100%",
           minHeight:"100%",
         }}
      >
        <img src={srcPass.fixed.base64} alt={alt ? alt : src.title} />
      </div>
    } else {
      return <Img
        className={`gatsby-image${className ? ` ${className}` : '' } fixed`}
        fixed={srcPass.fixed}
        alt={alt ? alt : src.localFile.title}
        style={{
           position: position,
           left: 0,
           top: 0,
           height: "100%",
           width: "100%",
           minHeight:"100%",
         }}
        />;
    }
  }

  //Fluid
  if (srcPass && srcPass.fluid) {
    return <Img
      className={`gatsby-image${className ? ` ${className}` : '' } fluid`}
      fluid={srcPass.fluid}
      alt={alt ? alt : src.localFile.title}
      style={{
         position: position,
         left: 0,
         top: 0,
         width: "100%",
         minHeight:"100%",
       }}
      />
  }

  //SVG
  if (src && src.localFile && src.localFile.publicURL) {
    return (
      <div className={`gatsby-image gatsby-image-wrapper svg${className ? ` ${className}` : '' }`}>
        {lazy ?
          <LazyLoad><img src={src.localFile.publicURL} className={className} alt={src.title} /></LazyLoad>
        :
          <img src={src.localFile.publicURL} className={className} alt={src.title} />
        }
      </div>
    )
  }

  //Preview image pass
  let srcUrlPass = src.url ? src.url : src
  srcUrlPass = src.source_url ? src.source_url : srcUrlPass
  return <div
    className={`gatsby-image gatsby-image-wrapper external${className ? ` ${className}` : '' }`}
    style={{
       position: position,
       left: 0,
       top: 0,
       width: "100%",
       minHeight:"100%",
     }}
  >
    {lazy ?
      <LazyLoad><img src={srcUrlPass} className={`${className ? ` ${className}` : '' }`} alt="" /></LazyLoad>
    :
      <img src={srcUrlPass} className={`${className ? ` ${className}` : '' }`} alt="" />
    }
  </div>

  return null
}
